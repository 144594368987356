import api from "../../axios";

export const salesReturnComplete = (params) => {
  return new Promise((resolve, reject) => {
    api
      .get("/api/v1/credit-note/sales-return-complete", {
        params: {
          search: params.search,
          length: params.length,
          page: params.page,
          order: params.order,
          sort: params.sort,
          // status: params.status ? params.status : 0,
          // type: params.type ? params.type : 0,
        },
      })
      .then((r) => {
        resolve(r);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

export const dataTableCreditNote = (params) => {
  return new Promise((resolve, reject) => {
    if (params.status != 4) {
      params.applied = false;
    }
    api
      .get("/api/v1/credit-note/data-table", {
        params: {
          search: params.search,
          length: params.length,
          page: params.page,
          order: params.order,
          sort: params.sort,
          status: params.status ? params.status : 0,
          applied: params.applied ? params.applied : false,
          customer_id: params.customer_id,
          is_ar_clearing: params.is_ar_clearing,
          territory_ids: params.territory_ids,
          type_in: params.type_in,
          type_not_in: params.type_not_in,
          is_approval: !params.is_approval ? false : true,
          // type: params.type ? params.type : 0,
        },
      })
      .then((r) => {
        console.log("><<<<<<<<<<<<aa", r);
        resolve(r);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

export const generateCreditNote = (request) => {
  return new Promise((resolve, reject) => {
    api
      .post("/api/v1/credit-note/sales-return-complete/generate", request)
      .then((r) => {
        resolve(r);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

export const createCreditNoteManual = (request) => {
  return api.post("/api/v1/credit-note/credit-note/manual", request, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const dataCreditNote = (params) => {
  return new Promise((resolve, reject) => {
    api
      .get("/api/v1/credit-note/data/" + params.ID)
      .then((r) => {
        resolve(r);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

export const deleteCreditNote = (params) => {
  return new Promise((resolve, reject) => {
    api
      .delete("/api/v1/credit-note/data/" + params.ID)
      .then((r) => {
        resolve(r);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

export const multipleUpdateStatus = (params) => {
  return new Promise((resolve, reject) => {
    api
      .post("/api/v1/credit-note/multiple-update-status", params)
      .then((r) => {
        resolve(r);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

export const updateCreditNote = (params) => {
  return new Promise((resolve, reject) => {
    api
      .post("/api/v1/credit-note/update/" + params.ID, params)
      .then((r) => {
        resolve(r);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

export const creditNoteArClearing = (params) => {
  return new Promise((resolve, reject) => {
    api
      .get("/api/v1/credit-note/ar-clearing", {
        params: {
          search: params.search,
          length: params.length,
          page: params.page,
          order: params.order,
          sort: params.sort,
          status: params.status ? params.status : 0,
          applied: params.applied ? params.applied : false,
          customer_id: params.customer_id,
          is_ar_clearing: params.is_ar_clearing,
          // type: params.type ? params.type : 0,
        },
      })
      .then((r) => {
        resolve(r);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

export const releaseCreditNote = (params) => {
  return new Promise((resolve, reject) => {
    api
      .patch("/api/v1/credit-note/release", params)
      .then((r) => {
        resolve(r);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
